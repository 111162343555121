import styled from "styled-components";
import { motion } from "framer-motion";

export const whiteText = '#f1f1f1'
export const greytext = '#c3cdd3'
//export const darkGreyText = '#888888'
export const darkGreyText = '#999999'
export const redColor = '#FF4D5A'
export const redColorOpacity = '#FF4D5A90'
export const blueColor = '#0b5476'
export const blueBackgroundColor = '#0E1117'
export const blackBackground = '#0a0a0a'
export const greenColor = 'rgb(100, 255, 218)'

// Dark blue hue color'#0D1016'
// Black color with grey tint '#0f0f0f'
// Almost pure black color '#0a0a0a'

export const OuterDiv = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:center;
  width: 100%;
  max-width: 1350px;
  height: fit-content;
  //min-height: ${props => props.minheight};
  //background-color: #060508;
  //padding-top: 2rem;
  overflow: visible;
  scroll-margin: 80px;

  @media screen and (max-width:1355px) {
      width: 93vw;
  }
  @media screen and (max-width:1100px) {
      width: 90vw;
      padding-top: 0px;
      //padding-top: 3rem;
  }
`;

export const TitleLeft = styled.h2`
  font-size: 2.2rem;
  min-height: 120%;
  font-weight: 400;
  margin:0;
  width: 100%;
  text-align: left;
  margin-top: 0.5rem;
  padding:0;
  color: #f1f1f1;
  max-width: 700px;

  @media screen and (max-width:1000px) {
     min-height: auto;
  }

  @media screen and (max-width:600px) {
      font-size: 1.375rem;
      line-height: 130%;
  }

`;

export const TitleGray = styled.span`
  color: ${greytext};
`;

// rgb(100, 255, 218);

export const TitleSmall = styled.h1`
  font-size: 1rem;
  min-height: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100%;
  text-align: ${props => props.leftAligned ? 'left' : 'center'};
  padding: 0;
  margin: 0;
  //margin-top: 15vh;
  color: ${props => props.color ? greenColor : redColor};

  @media screen and (max-width: 600px) {
      font-size: 0.8rem;
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: left;
  }

`;

export const Title = styled.h2`
  font-size: 3rem;
  min-height: 4rem;
  font-weight: 500;
  margin:0;
  margin-left: auto; /* centers the title horizontally */
  margin-right: auto; /* centers the title horizontally */
  text-align: center; /* centers the title text horizontally */
  margin-top: 0.5rem;
  padding:0;
  color: #f1f1f1;

  @media screen and (max-width:1000px) {
     min-height: auto;
     white-space: normal;
      width: 90vw;
      margin:0;
      text-align: left;
  }

  @media screen and (max-width:600px) {
      font-size: 2rem;
      line-height: 130%;
  }
`;

export const DetailedText = styled.p`
    color: ${greytext};
    line-height: 140%;
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.7px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 3rem;
    margin-top: 1rem;
    height: fit-content;

    @media screen and (max-width:1000px) {
      font-size: 1.3rem;
      white-space: normal;
      width: 90vw;
    }

    @media screen and (max-width:600px) {
      font-size: 1.05rem;
      letter-spacing: 0.3px;
      margin-bottom: 1rem;
      text-align:  ${props => props.textalign ? 'center' : 'left'};
    }
    
`;

export const DetailedTextLeft = styled.h4`
    color:#888888;
    line-height: 1.6em;
    font-weight: 300;
    font-size: 1.3rem;
    letter-spacing: 0.7px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 3rem;


    @media screen and (max-width:1000px) {
      font-size: 1.3rem;
      white-space: normal;
      width: 90vw;
    }

    @media screen and (max-width:600px) {
      font-size: 1.05rem;
      width: 98vw;
      letter-spacing: 0.3px;
      margin-bottom: 2rem;
    }
    
`;






