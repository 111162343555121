import React, { useContext } from 'react'
import LanguageContext from '../../context/languageContext'
import styled from 'styled-components'

const StyledSpan = styled.span`
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    text-transform: inherit;
`;

export default function TranslateText({ swedish, english }) {
    const { language } = useContext(LanguageContext)

    if (language == 'swe') {
        return (
            <StyledSpan>{swedish}</StyledSpan>
        )
    } else {
        return (
            <StyledSpan>{english}</StyledSpan>
        )
    }
}