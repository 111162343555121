import dynamic from 'next/dynamic';
import React from "react";
const Footer = dynamic(() => import('./footer/footer'));
import Navbar from './header/navbar';
import { LocalFonts } from '../../styles/fonts/fonts';

export default function Layout({ children }) {

    return (
        <div className={`contentBasic ${LocalFonts.className}`}>
            <Navbar/>
            {children}
            <Footer />
        </div>
    )

}

