import Image from 'next/image';
import React, { useContext } from 'react';
import styled from 'styled-components';
import LanguageContext from '../../../context/languageContext';

const LanguageToggleContainer = styled.div`
    display: flex;
    gap: 8px;

    @media screen and (min-width:1000px) {
      margin-left: 1rem;
  }

`;

const ToggleButton = styled.button`
    padding: 8px;
    background: transparent;
    border: none;
    margin: auto;
    color: ${props => props.selected ? 'white' : 'black'};
    border-bottom: ${props => props.selected ? '1px solid #ffffff' : 'none'};
    border-radius: 0px;
    padding-bottom: ${props => props.selected ? '3px' : 'initial'};
    padding-top: ${props => props.selected ? '3px' : 'initial'};

    &:hover{
        cursor: pointer;
    }
`;

export default function LanguageToggle() {
    const { language, handleLanguage } = useContext(LanguageContext);

    return (
        <LanguageToggleContainer>
            <ToggleButton 
                selected={language === 'swe'} 
                onClick={() => handleLanguage('swe')}
                aria-label="Swedish"
            >
                <Image src="/images/sweden.svg" alt={'Swedish flag'} height={30} width={30} />
            </ToggleButton>
            <ToggleButton 
                selected={language === 'eng'} 
                onClick={() => handleLanguage('eng')}
                aria-label="English"
            >
                <Image src="/images/britain.svg" alt={'British flag'} height={30} width={30} />
            </ToggleButton>
        </LanguageToggleContainer>
    );
}
