import React from 'react';
import styled from 'styled-components';
import TixyLogo from '../partials/tixyLogo';
import { greytext } from '../../landingPage/styledComponents';
import TranslateText from '../../utils/translateText';
import LanguageToggle from '../partials/languageToggle';
import Link from 'next/link';

const HeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //background-color: inherit;
  max-width: 1350px;
  height: 64px;
  
  @media screen and (max-width:1350px) {
    max-width: 90vw;
  }

  @media screen and (max-width:1000px) {
   display: none;
  }
`;

const LogoDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 130px;
  height: 64px;
`;

const ButtonsInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width:auto;
  flex: 1;
  height:100%;
  gap:40px;
`;

const MiddleButton = styled.div`
    color: ${greytext};
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
    //padding-left: 20px;

    &:hover {
      color: #F1F1F1;
      cursor: pointer;
      border-bottom:1px solid #f1f1f190;
    }
`;

export default function NavbarDesktop({ buttonItems }) {

  return (
    <HeaderInner>
      <LogoDiv>
        <TixyLogo />
      </LogoDiv>
      <ButtonsInner>
        {
          buttonItems.map((item) => {
            return (
              <Link href={item.link} key={item.swe}>
                <MiddleButton><TranslateText swedish={item.swe} english={item.eng}> </TranslateText></MiddleButton>
              </Link>
            )
          })
        }
        <LanguageToggle />
      </ButtonsInner>
    </HeaderInner>
  );
}

// {item.icon}