import React, { useEffect, useState } from 'react';
import NavbarMobile from './navbarMobile';
import styled from 'styled-components';
import NavbarDesktop from './navbarDesktop';
import { linkAbout, linkClient, linkContact, linkProductOptimization,linkProductImplementation, linkProductModules } from '../links';
import { BsArrowDownShort } from 'react-icons/bs'

const LinkSpan = styled.div`
    color: #f1f1f190;
    font-size: 0.8rem;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding-left: 5px;
    line-height: 100%;
    transform: rotate(-135deg);
`;

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: ${props => props.background};
  z-index: 99;
  transition: 0.2s linear;
  box-sizing: border-box;

  @media screen and (max-width:1000px) {
    transition: 0.8s linear;
  }
`

const buttonItems = [
    {
        swe: 'Case',
        eng: 'Case',
        icon: <LinkSpan><BsArrowDownShort /></LinkSpan>,
        link: linkClient
    },
    {
        swe: 'Implementation',
        eng: 'Implementation',
        icon: <LinkSpan><BsArrowDownShort /></LinkSpan>,
        link: linkProductImplementation
    },
    {
        swe: 'Moduler',
        eng: 'Modules',
        icon: <LinkSpan><BsArrowDownShort /></LinkSpan>,
        link: linkProductModules
    },
    {
        swe: 'Om oss',
        eng: 'About',
        icon: <LinkSpan><BsArrowDownShort /></LinkSpan>,
        link: linkAbout
    },
    {
        swe: 'Kontakt',
        eng: 'Contact',
        icon: <LinkSpan><BsArrowDownShort /></LinkSpan>,
        link: linkContact
    }
]

const lerpValue = (start, end, factor) => start + factor * (end - start);

const lerpColor = (color1, color2, factor) => {
    let [r1, g1, b1] = color1;
    let [r2, g2, b2] = color2;

    let r = lerpValue(r1, r2, factor);
    let g = lerpValue(g1, g2, factor);
    let b = lerpValue(b1, b2, factor);

    return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}, 0.9)`;
};

export default function Navbar({ }) {
    const [scroll, setScroll] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState("transparent");

    const handleScroll = () => {
        const position = window.scrollY;

        setScroll(position)

        if (position > 900 || window.location.pathname != '/') {
            const totalScrollableHeight = document.body.scrollHeight - window.innerHeight;

            // Calculate the factor of how much the page has been scrolled
            const factor = Math.min(1, position / totalScrollableHeight);

            const startColor = [14, 16, 22]; // RGB for #0E1016
            const endColor = [10, 10, 10];  // RGB for #0a0a0a
            const color = lerpColor(startColor, endColor, factor);

            setBackgroundColor(color);
        } else {
            setBackgroundColor("transparent")
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <HeaderContainer background={scroll > 100 ? backgroundColor : "transparent"} >
            <NavbarMobile buttonItems={buttonItems} />
            <NavbarDesktop buttonItems={buttonItems} />
        </HeaderContainer>
    )
}
