
// Product page links
export const linkClient = '/products#clients'
export const linkProductModules= '/products#modules'
export const linkProductOptimization = '/products#optimization'
export const linkProductAutomation = '/products#process-automation'
export const linkProductAnalytics = '/products#process-analytics'
export const linkProductImplementation= '/products#implementation'

// Navbar links
export const linkAbout = '/about'
export const linkContact =  '/contact'
