
import { Squash as Hamburger } from 'hamburger-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { blackBackground, greytext } from '../../landingPage/styledComponents';
import TranslateText from '../../utils/translateText';
import LanguageToggle from '../partials/languageToggle';
import TixyLogo from '../partials/tixyLogo';
import Link from 'next/link';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.background};
  z-index: 999;
  box-sizing: border-box;
  padding: 10px;
  @media screen and (min-width:1000px) {
   display: none;
  }
`;

export const HeaderInner = styled.div`
  display: ${props => props.display};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: ${props => props.height};
  border-top: ${props => props.border};
  background-color: #0a0a0a;
  box-sizing: border-box;
`;

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    border-bottom: 0.5px solid #f1f1f180 ;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;

    &:hover {
      color: #F1F1F1;
      cursor: pointer;
    }
`;

const MiddleButton = styled.div`
    color: ${greytext};
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
    transition: all 50ms linear;
    padding-left: 20px;
    text-decoration: none;
    &:hover {
      color: #F1F1F1;
      cursor: pointer;
    }
`;

const TextSpan = styled.span`
    color: ${greytext};
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
    padding-bottom: 25px;
    padding-top: 25px;
`;


const ColumnWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media screen and (min-width:1000px) {
   display: none;
  }
`;

const RowWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
  box-sizing: border-box;
`;


const LogoDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
  height: 50px;
`;

export default function NavbarMobile({ buttonItems }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <ColumnWrap>
      <HeaderContainer background={isOpen ? blackBackground : 'inherit'} >
        <RowWrap pl={'5px'} pr={'0px'}>
          <LogoDiv>
            <TixyLogo />
          </LogoDiv>
          <Hamburger toggled={isOpen} toggle={setOpen} size={22} />
        </RowWrap>
      </HeaderContainer>
      <HeaderInner height={isOpen ? 'fit-content' : '0px'} display={isOpen ? 'flex' : 'none'} border={isOpen ? '0.5px solid #f1f1f150' : 'none'} >
        {
          buttonItems.map((item) => {
            return (
              <ButtonDiv key={item.link}>
                <Link href={item.link} passHref>
                  <MiddleButton><TranslateText swedish={item.swe} english={item.eng}> </TranslateText>{item.icon}</MiddleButton>
                </Link>
              </ButtonDiv>
            )
          })
        }
        <RowWrap pl={'20px'} pr={'20px'}>
          <TextSpan> <TranslateText swedish={'Språk'} english={'Language'}></TranslateText> </TextSpan>
          <LanguageToggle />
        </RowWrap>
      </HeaderInner>
    </ColumnWrap>
  );
}

