import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function TixyLogo() {
  // https://hackernoon.imgix.net/images/0*3IFEy-hfoIpgFjBl.gif

  return (
    <Link href={'/'}>
      <Image
        fill
        style={{ objectFit: "contain" }}
        src={'/images/paretro.png'}
        alt={'AlgoFlow logo'}
      />
    </Link>
  );
}

